import {AppInsightsAdapter} from '@hconnect/common/logging'
import {NotificationProvider} from '@hconnect/uikit'
import {useUserCookieConsent} from '@hconnect/uikit/src/lib/Oreo/hooks/useUserCookieConsent'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {get} from 'lodash'
import {useEffect, useReducer, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {AuthProvider} from '../../common/authentication/context'

import {App} from './App'
import {loginFlow} from './App.global'
import {LandingPage} from './LandingPage'
import {ConfirmationServiceProvider} from './OrderIntake/components/ConfirmationDialog/ConfirmationService'
import {OrderCancellationProvider} from './Organisms/Cancellation/OrderCancellationService'
import {FeaturesProvider} from './Organisms/Features'
import {BulkCementOrderIntakeProvider} from './Organisms/OrderIntake/BulkCementOrderIntake.provider'
import {MaintenanceScreen} from './Pages/MaintenanceScreen/MaintenanceScreen'
import {useMaintenanceScreen} from './Pages/MaintenanceScreen/MaintenanceScreenProvider'
import {PermissionProvider} from './Permissions'
import {UserContext} from './UserProfile'

enum AppState {
  Loading,
  LoggedIn,
  LoggedOut
}

interface AppContainerState {
  state: AppState
  userId?: string
  username?: string
  isTestUser?: boolean
}

const initialState: AppContainerState = {
  state: AppState.Loading
}

const appContainerReducer = (state: AppContainerState, action) => {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        state: AppState.LoggedIn,
        userId: action.userId,
        username: action.username,
        isTestUser: action.isTestUser
      }

    case 'LOGGED_OUT':
      return {
        state: AppState.LoggedOut
      }

    default:
      return state
  }
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
})

export const AppContainer = (props) => {
  const location = useLocation()
  const [loginState, setLoginState] = useState<boolean>(false)

  const [appState, dispatch] = useReducer(appContainerReducer, initialState)
  const analyticsConsentGiven = useUserCookieConsent('Statistics')

  useEffect(() => {
    const disableAnalytics =
      !analyticsConsentGiven ||
      process.env.CYPRESS_COVERAGE === 'true' ||
      (appState.state === AppState.LoggedIn &&
        process.env.REACT_APP_STAGE === 'prod' &&
        appState.isTestUser)

    disableAnalytics ? AppInsightsAdapter.stopTracking() : AppInsightsAdapter.allowTracking()
  }, [analyticsConsentGiven, appState])

  useEffect(() => {
    const loader = async () => {
      const loginFlowResponse = await loginFlow.getLoginState()
      setLoginState(loginFlowResponse.loggedIn)
      if (loginFlowResponse.loggedIn) {
        dispatch({
          type: 'LOGGED_IN',
          userId: loginFlowResponse.decodedToken.user_id,
          username: loginFlowResponse.decodedToken.email,
          isTestUser: loginFlowResponse.decodedToken.isTestUser === 'true'
        })
      } else {
        if (location.pathname.startsWith('/receiveInvite/')) {
          const countryId: string = get(location.pathname.split('/'), [2], '')
          await loginFlow.startRegistrationProcess('create-account', countryId)
        } else if (location.pathname.startsWith('/downloadRequests')) {
          await loginFlow.startLoginProcess()
        } else {
          dispatch({type: 'LOGGED_OUT'})
        }
      }
    }
    void loader()
    // eslint-disable-next-line
  }, [])

  const {maintenanceState} = useMaintenanceScreen()

  if (maintenanceState?.showMaintenanceScreen) {
    return (
      <QueryClientProvider client={queryClient}>
        <MaintenanceScreen
          startDate={maintenanceState.startDate}
          durationInHours={maintenanceState.durationInHours}
        />
      </QueryClientProvider>
    )
  }

  switch (appState.state) {
    case AppState.LoggedIn: {
      const {userId, username} = appState
      return (
        <QueryClientProvider client={queryClient}>
          <UserContext.Provider value={userId}>
            <NotificationProvider>
              <AuthProvider>
                <FeaturesProvider>
                  <PermissionProvider userId={userId} username={username}>
                    <BulkCementOrderIntakeProvider>
                      <ConfirmationServiceProvider>
                        <OrderCancellationProvider>
                          <App {...props} {...(loginState ? {loggedIn: true} : {})} />
                        </OrderCancellationProvider>
                      </ConfirmationServiceProvider>
                    </BulkCementOrderIntakeProvider>
                  </PermissionProvider>
                </FeaturesProvider>
              </AuthProvider>
            </NotificationProvider>
          </UserContext.Provider>
        </QueryClientProvider>
      )
    }

    case AppState.LoggedOut:
      return (
        <QueryClientProvider client={queryClient}>
          <FeaturesProvider isLoggedOut={true}>
            <LandingPage />
          </FeaturesProvider>
        </QueryClientProvider>
      )

    case AppState.Loading:
    // fall through

    default:
      return null
  }
}
