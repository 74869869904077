/* eslint-disable complexity */

export const getLanguageTitle = (language: string, country?: string): string | null => {
  const languageCode = language.toLowerCase()
  const countryCode = country?.toLowerCase()
  switch (languageCode) {
    case 'en':
      return 'English'
    case 'da':
      return 'Dansk'
    case 'de':
      return 'Deutsch'
    case 'bs':
      return 'Bosanski'
    case 'nl':
      return 'Nederlands'
    case 'fr':
      return 'Français'
    case 'bg':
      return 'български'
    case 'cs':
      return 'Čeština'
    case 'es':
      return 'Español'
    case 'ka':
      return 'ქართული ენა'
    case 'kk':
      return 'қазақ'
    case 'zn':
      switch (countryCode) {
        case 'hk':
          return '中文'
        default:
          return '普通话'
      }
    case 'hu':
      return 'Magyarul'
    case 'it':
      return 'Italiano'
    case 'no':
      return 'Norsk'
    case 'pl':
      return 'Polskie'
    case 'ro':
      return 'Română'
    case 'ru':
      return 'Pусский'
    case 'sv':
      return 'Svenska'
    case 'th':
      return 'ไทย'
    default:
      return null
  }
}
