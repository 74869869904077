import {connectRouter} from 'connected-react-router'
import {Reducer, combineReducers} from 'redux'

import asyncJobQueueReducer from './AsyncJobQueue/AsyncJobQueue.reducer'
import {AsyncJobQueueState} from './AsyncJobQueue/AsyncJobQueue.types'
import {brandingReducer, BrandingState} from './Organisms/Branding'
import {countriesReducer} from './Organisms/Countries/Countries.reducer'
import {CountriesState} from './Organisms/Countries/Countries.types'
import {customersReducer} from './Organisms/Customers'
import {CustomerStateType} from './Organisms/Customers/Action.types'
import {documentsReducer} from './Organisms/Documents'
import {errorsReducer} from './Organisms/Errors'
import {FeedbackStateType} from './Organisms/Feedback/Action.types'
import {feedbackReducer} from './Organisms/Feedback/Feedback.reducer'
import {PayersStateType, payersReducer} from './Organisms/Payers'
import {PaymentOptionsStateType, paymentOptionsReducer} from './Organisms/Payments'
import {projectsReducer} from './Organisms/Projects'
import {ProjectsStateType} from './Organisms/Projects/Action.types'
import {sitesReducer} from './Organisms/Sites'
import {SitesStateType} from './Organisms/Sites/Action.types'
import materialTestReducer, {MaterialTestState} from './Pages/Certificate/Rmc/MaterialTest.reducer'
import invoicesReducer, {InvoicesState} from './Pages/Finance/Invoices/Invoice.reducer'
import orderReducer, {OrderState} from './Pages/Order/Order.reducer'
import overviewReducer, {OverviewState} from './Pages/Overview/Overview.reducer'
import {PermissionsStateType} from './Permissions/Action.types'
import {permissionsReducer} from './Permissions/permissions.reducer'
import {AppState} from './Root.store'
import {userProfileReducer, UserProfileState} from './UserProfile/UserProfile.reducer'

export const resetEnhancer =
  (rootReducer: Reducer) =>
  (
    state: AppState | undefined,
    // TODO: more typed action bellow
    action: any
  ) => {
    if (state) {
      switch (action.type) {
        case 'RESET': {
          const newState = rootReducer(undefined, action)
          newState.router = state.router
          return newState
        }

        default:
          return rootReducer(state, action)
      }
    }
  }

// TODO: "any" type should be replaced with
// actual state types as soon as they are migrated to TS
export interface RootState {
  router: any
  overview: OverviewState
  finance: InvoicesState
  order: OrderState
  materialTest: MaterialTestState
  projects: ProjectsStateType
  sites: SitesStateType
  permissions: PermissionsStateType
  asyncjobqueue: AsyncJobQueueState
  documents: any
  errors: any
  customers: CustomerStateType
  userProfile: UserProfileState
  branding: BrandingState
  feedback: FeedbackStateType
  payers: PayersStateType
  countries: CountriesState
  paymentOptions: PaymentOptionsStateType
}

export default (history) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    overview: overviewReducer,
    finance: invoicesReducer,
    order: orderReducer,
    materialTest: materialTestReducer,
    projects: projectsReducer,
    sites: sitesReducer,
    permissions: permissionsReducer,
    asyncjobqueue: asyncJobQueueReducer,
    documents: documentsReducer,
    errors: errorsReducer,
    customers: customersReducer,
    userProfile: userProfileReducer,
    branding: brandingReducer,
    feedback: feedbackReducer,
    payers: payersReducer,
    countries: countriesReducer,
    paymentOptions: paymentOptionsReducer
  })
