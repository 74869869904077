export const EXPORT_FILENAME_ORDERS = 'orders-deliveries'
export const EXPORT_SHORTNAME_ORDERS = 'orders'

export const EXPORT_FILENAME_INVOICES = 'invoice-deliveries'
export const EXPORT_SHORTNAME_INVOICES = 'invoice'
export const EXPORT_FILENAME_TICKETS = 'tickets'
export const EXPORT_FILENAME_DATEFORMAT = 'YYYY-MM-DD'

export const EXPORT_FILENAME_MATERIAL_TESTS = 'material-tests'
export const EXPORT_SHORTNAME_MATERIAL_TESTS = 'mat-tests'

export const DEFAULT_CUSTOMER = 'default_customer'
export const DEFAULT_PAYER = 'default_payer'
